<template>
  <el-dialog
    id="videoDialog"
    :title="title + '-监控'"
    :visible.sync="show"
    destroy-on-close
    @opened="$emit('opened')"
    @close="reset"
  >
    <videoPlayer
      v-if="videoLoad"
      :channelId="channelId"
      :deviceRegisterId="deviceRegisterId"
      :video-url="videoUrl"
      :aliRtcAuthInfo="aliRtcAuthInfo"
    />
    <slot />
  </el-dialog>
</template>

<script>
import videoPlayer from "@/components/videoPlayer.vue";

export default {
  components: { videoPlayer },
  props: {
    videoLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      title: "对讲",
      channelId: "",
      deviceRegisterId: "",
      videoUrl: "",
      aliRtcAuthInfo: null,
    };
  },
  methods: {
    reset() {
      this.channelId = "";
      this.deviceRegisterId = "";
      this.videoUrl = "";
      this.aliRtcAuthInfo = null;
    },
  },
};
</script>

<style lang="scss">
#videoDialog {
  .el-dialog {
    width: 50%;
    margin-top: 20px !important;
    video {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
